import { useState, useEffect } from "react";
import AuthLayout from "../../../components/layouts/auth";
import ApiRequest from "../../../libs/ApiCall";
import AddTransaction from "./add-transaction";
import moment from "moment";
import debounce from 'lodash.debounce';
import { Minus } from "lucide-react";
import { formatNumber, getCurrency, SmoothScroll } from "../../../libs/helper-support";
import PaginationType from "../../../types/pagination-type";
import InputField from "../../../components/form/input-field";

const Transactions = () => {

    interface TransactionType {
        uuid: string
        issuer: string
        type: string
        security: string
        series: string
        transactionSize: string
        regulatorQuasi: string
        transactionSizeCurrency: string
        createdAt: string
        role: string
        completionIssueDate:string
    }

    const [transactions, setTransactions] = useState<TransactionType[]>([]);

    const [pageLoader, setPageLoader] = useState<boolean>(false);

    const [pageData, setPageData] = useState<PaginationType>({} as PaginationType);
    const [page, setPage] = useState<number>(1);

    const [search, setSearch] = useState<string>('');

    const getTransactions = async () => {
        const request = await ApiRequest({endpoint: `/transaction?page=${page}`, method: 'GET'});
        if ( request.status !== '200' ) return
        setTransactions(request.data.items);
        setPageData(request.data);
        SmoothScroll();
    }

    useEffect(() => {
        getTransactions();
    }, [page]);

    const onSuccessAddingTransaction = (data:TransactionType) => {
        setTransactions([...[data], ...transactions]);
    }

    const fetchSuggestions = debounce(async (searchQuery) => {
        const request = await ApiRequest({endpoint: `/transaction?searchParams=${searchQuery}`, method: 'GET'});
        if ( request.status !== '200' ) return
        setTransactions(request.data.items);
        setPageData(request.data);
        SmoothScroll();
      }, 300);

      useEffect(() => {
        search ? fetchSuggestions(search) : getTransactions();
        return fetchSuggestions.cancel;
      }, [search]);

    return (
        <AuthLayout pageLoader={pageLoader}>
            <div className="page-outer">
                <div className="flex items-center justify-between">
                    <h1 className="page-title">Transactions</h1>
                    <div className="lg:w-1/3">
                            <InputField
                                label="Search by issuer name"
                                labelClass="hidden"
                                type="search"
                                placeholder="Search by issuer name"
                                value={search}
                                onChangeInput={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    <div className="flex justify-evenly">
                        
                        <AddTransaction buttonDesign={<AddButtonDesign/>} onSuccess={onSuccessAddingTransaction} onLoading={(state:boolean) => setPageLoader(state)}/>
                    </div>
                </div>
                <div className='relative overflow-x-auto'>
                    <table className='mt-5 text-tertiary text-[14px] w-full relative z-10'>
                        <thead>
                            <tr className='bg-[#F8F8F8]'>
                                <td className="px-3 py-3">Client/Issuer</td>
                                <td className="px-6 py-3">Type</td>
                                <td className="px-6 py-3">Security</td>
                                <td className="px-6 py-3 text-center">Series</td>
                                <td className="px-6 py-3">Size</td>
                                <td className="px-6 py-3">Regulator</td>
                                <td className="px-6 py-3">Date</td>
                                <td className="px-6 py-3">Role</td>
                            </tr>
                        </thead>
                        <tbody className="relative">
                        {transactions.map((transaction, i) => (
                            <tr key={i} className='border-b border-[#E6EAF0]'>
                                <td className="px-3 py-3">{transaction.issuer}​</td>
                                <td className="px-6 py-3">{transaction.type}</td>
                                <td className="px-6 py-3">{transaction.security ?? <Minus size={18}/>}</td>
                                <td className="px-6 py-3 text-center">{transaction.series ?? <Minus size={18}/>}</td>
                                <td className="px-6 py-3">
                                {getCurrency(transaction.transactionSizeCurrency)}{formatNumber(transaction.transactionSize)}
                                </td>
                                <td className="px-6 py-3">{transaction.regulatorQuasi ?? <Minus size={18}/>}</td>
                                <td className="px-6 py-3">{moment(transaction.createdAt).format('DD MMM YYYY')}</td>
                                <td className="px-6 py-3 capitalize">{transaction.role ?? <Minus size={18}/>}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex items-center justify-between mt-6">
                    <div className="flex items-center space-x-2 font-[400]">
                        <span>Show:</span>
                        <select className="flex px-2 py-1 border rounded">
                            <option value="20">20</option>
                            <option value="35">35</option>
                            <option value="50">50</option>
                        </select>
                        <span>per page</span>
                    </div>
                    <div className="flex space-x-3">
                        {page > 1 && <button onClick={() => setPage(page - 1)} type="button" className="font-[600] text-[14px] pryBtnOutline border-[#E7E7E7] text-black hover:bg-transparent hover:text-black">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.5305 18.9693C15.6002 19.039 15.6554 19.1217 15.6932 19.2128C15.7309 19.3038 15.7503 19.4014 15.7503 19.4999C15.7503 19.5985 15.7309 19.6961 15.6932 19.7871C15.6554 19.8781 15.6002 19.9609 15.5305 20.0306C15.4608 20.1002 15.3781 20.1555 15.287 20.1932C15.196 20.2309 15.0984 20.2503 14.9999 20.2503C14.9013 20.2503 14.8037 20.2309 14.7127 20.1932C14.6216 20.1555 14.5389 20.1002 14.4692 20.0306L6.96923 12.5306C6.8995 12.4609 6.84418 12.3782 6.80644 12.2871C6.76869 12.1961 6.74927 12.0985 6.74927 11.9999C6.74927 11.9014 6.76869 11.8038 6.80644 11.7127C6.84418 11.6217 6.8995 11.539 6.96923 11.4693L14.4692 3.9693C14.61 3.82857 14.8008 3.74951 14.9999 3.74951C15.1989 3.74951 15.3898 3.82857 15.5305 3.9693C15.6712 4.11003 15.7503 4.30091 15.7503 4.49993C15.7503 4.69895 15.6712 4.88982 15.5305 5.03055L8.56017 11.9999L15.5305 18.9693Z" fill="#888888"/>
                            </svg>
                            <span>Previous</span>
                        </button>}
                        {pageData.hasMorePages && <button onClick={() => setPage(page + 1)} type="button" className="font-[600] text-[14px] pryBtnOutline border-[#E7E7E7] text-black hover:bg-transparent hover:text-black">
                            <span>Next</span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.0306 12.5306L9.53055 20.0306C9.46087 20.1002 9.37815 20.1555 9.2871 20.1932C9.19606 20.2309 9.09847 20.2503 8.99993 20.2503C8.90138 20.2503 8.8038 20.2309 8.71276 20.1932C8.62171 20.1555 8.53899 20.1002 8.4693 20.0306C8.39962 19.9609 8.34435 19.8781 8.30663 19.7871C8.26892 19.6961 8.24951 19.5985 8.24951 19.4999C8.24951 19.4014 8.26892 19.3038 8.30663 19.2128C8.34435 19.1217 8.39962 19.039 8.4693 18.9693L15.4396 11.9999L8.4693 5.03055C8.32857 4.88982 8.24951 4.69895 8.24951 4.49993C8.24951 4.30091 8.32857 4.11003 8.4693 3.9693C8.61003 3.82857 8.80091 3.74951 8.99993 3.74951C9.19895 3.74951 9.38982 3.82857 9.53055 3.9693L17.0306 11.4693C17.1003 11.539 17.1556 11.6217 17.1933 11.7127C17.2311 11.8038 17.2505 11.9014 17.2505 11.9999C17.2505 12.0985 17.2311 12.1961 17.1933 12.2871C17.1556 12.3782 17.1003 12.4609 17.0306 12.5306Z" fill="#888888"/>
                            </svg>
                        </button>}
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
}

const AddButtonDesign = () => {
    return (
        <button className="pryBtn w-fit" type="button">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3125 10C18.3125 10.2486 18.2137 10.4871 18.0379 10.6629C17.8621 10.8387 17.6236 10.9375 17.375 10.9375H11.4375V16.875C11.4375 17.1236 11.3387 17.3621 11.1629 17.5379C10.9871 17.7137 10.7486 17.8125 10.5 17.8125C10.2514 17.8125 10.0129 17.7137 9.83709 17.5379C9.66127 17.3621 9.5625 17.1236 9.5625 16.875V10.9375H3.625C3.37636 10.9375 3.1379 10.8387 2.96209 10.6629C2.78627 10.4871 2.6875 10.2486 2.6875 10C2.6875 9.75136 2.78627 9.5129 2.96209 9.33709C3.1379 9.16127 3.37636 9.0625 3.625 9.0625H9.5625V3.125C9.5625 2.87636 9.66127 2.6379 9.83709 2.46209C10.0129 2.28627 10.2514 2.1875 10.5 2.1875C10.7486 2.1875 10.9871 2.28627 11.1629 2.46209C11.3387 2.6379 11.4375 2.87636 11.4375 3.125V9.0625H17.375C17.6236 9.0625 17.8621 9.16127 18.0379 9.33709C18.2137 9.5129 18.3125 9.75136 18.3125 10Z" fill="white"/>
            </svg>
            <span>New Transaction</span>
        </button>
    );
}

export default Transactions