import { useState, useEffect } from "react";
import InputField from "../../../../components/form/input-field";
import TextareaField from "../../../../components/form/textarea";
import TickerField from "../../../../components/form/ticker-field";
import ApiRequest from "../../../../libs/ApiCall";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { convertToBase64, getFileSizeInMB } from "../../../../libs/helper-support";
import { ErrorFlash, SuccessFlash } from "../../../../libs/flash-notification";
import Tooltip from "../../../../components/tooltip";

interface UpdateProfileType {
    onLoading?: (state:boolean) => void
}   

const UpdateProfile = ({onLoading} : UpdateProfileType) => {
    
    interface AccountType {
        uuid:string,
        contactRepresentatives: representativesType[],
        secRegistrationNumber:string,
        status:string,
        nameInstitution: string,
        registeredAddress: string,
        contactAddress: string,
        logo?: string,
    }

   

    const [profileDetails, setProfileDetails] = useState<AccountType>({} as AccountType);

    const [sameAddress, setSameAddress] = useState<boolean>(false);
    type representativesType = {
        name: string,
        position: string
        mobile_number:string,
        email_address:string,
        is_primary: boolean
    }
    const [representatives, setRepresentatives] = useState<representativesType[]>([{is_primary:false}, {is_primary:false}] as representativesType[]);

    
    const removeRepresentative = (index:number) => {
        const newRepresentatives = [...representatives];
        newRepresentatives.splice(index, 1);
        setRepresentatives(newRepresentatives);
    };

    const onChangeRepresentativeInput = (index:number, field:string, e:React.ChangeEvent<HTMLInputElement>) => {
        const newRepresentatives = representatives.map((rep, i) => {
            if (i === index) {
                const target = e.target as HTMLInputElement | HTMLTextAreaElement;
                return { ...rep, [field]: target.value };
            }
            return rep;
        });
        setRepresentatives(newRepresentatives);
    };

    const onPrimaryContact = (index:number) => {
        const newRepresentatives = representatives.map((rep, i) => {
            return { ...rep, is_primary: i === index };
        });
        setRepresentatives(newRepresentatives);
    }

    const getAccount = async () => {
        const request = await ApiRequest({endpoint: '/profile', method: 'GET'});
        if ( request.status !== '200' ) return;
        setProfileDetails(request.data);
        reset(request.data);
        setSameAddress(request.data.sameAddress);
        setRepresentatives(request.data.contactRepresentatives);
    }

    useEffect(() => {
        getAccount();
    }, []);

    const formSchema = yup.object({
        nameInstitution: yup.string(),
        registeredAddress: yup.string().required('Registered address is required'),
        contactAddress: yup.string().required('Contact address is required'),
        secRegistrationNumber: yup.string(),
        contactRepresentatives: yup.array().of(
            yup.object({
                name: yup.string(),
                position: yup.string(),
                mobile_number: yup.string(),
                email_address: yup.string(),
                is_primary: yup.boolean()
            })
        )
    });

    const  {
        setValue,
        setError,
        formState: {errors},
        handleSubmit,
        getValues,
        reset,
    } = useForm({resolver: yupResolver(formSchema)}); 

    const onRegisteredAddress = (value:string) => {
        sameAddress && setValue('contactAddress', value);
    }

    const onSameAddress = (state:boolean) => {
        setSameAddress(state);
        setValue('contactAddress', getValues('registeredAddress'));
    }

    const onSubmit = async (data:any) => {
        data['contactRepresentatives'] = representatives;
        onLoading && onLoading(true);
        const request = await ApiRequest({endpoint: '/update-profile', method:'POST', payload: data});
        onLoading && onLoading(false);

        if (request.status === '200'){
            SuccessFlash("Profile updated successfully");
            return;
        }

        ErrorFlash(request.message);

        if (request.status === "payloadValidationError") {
            for (const [field, messages] of Object.entries(request.data)) {
                const input_field : any = field ;
                const error_message : any = messages
                setError(input_field, { type: "manual", message: error_message.join(", ") });
            }
        }
    }

    const onLogoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        const base64 = await convertToBase64(file);
        const size = getFileSizeInMB(file);
        if (size > 2) {
            return;
        }

        const request = await ApiRequest({endpoint: '/upload-logo', method:'POST', payload: {
            imageInBase64: base64,
            imageExtension: file?.type.split('/')[1]
        }});

        event.target.value = "";

        if ( request.status === '200' ) {
            setProfileDetails({...profileDetails, logo: request.data.logo});
            //clearErrors('logo');
            SuccessFlash("Logo uploaded successfully");
            return;
        }
    }
        
    return (
        <div className="grid lg:px-10 gap-y-4">
            <div className="items-center lg:space-x-5 lg:flex">
                <div className="bg-[#F8F8F8] p-10 flex justify-center items-center space-x-2 relative rounded border border-[#E7E7E7] lg:w-[240px] h-[120px] overflow-hidden">
                    {profileDetails.logo ? <img src={profileDetails.logo} alt="logo"/> : 
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.75 3.75H4.25C3.85218 3.75 3.47064 3.90804 3.18934 4.18934C2.90804 4.47064 2.75 4.85218 2.75 5.25V18.75C2.75 19.1478 2.90804 19.5294 3.18934 19.8107C3.47064 20.092 3.85218 20.25 4.25 20.25H20.75C21.1478 20.25 21.5294 20.092 21.8107 19.8107C22.092 19.5294 22.25 19.1478 22.25 18.75V5.25C22.25 4.85218 22.092 4.47064 21.8107 4.18934C21.5294 3.90804 21.1478 3.75 20.75 3.75ZM20.75 5.25V14.8828L18.3059 12.4397C18.1666 12.3004 18.0013 12.1898 17.8193 12.1144C17.6372 12.039 17.4422 12.0002 17.2452 12.0002C17.0481 12.0002 16.8531 12.039 16.6711 12.1144C16.489 12.1898 16.3237 12.3004 16.1844 12.4397L14.3094 14.3147L10.1844 10.1897C9.9031 9.9086 9.52172 9.7507 9.12406 9.7507C8.72641 9.7507 8.34503 9.9086 8.06375 10.1897L4.25 14.0034V5.25H20.75ZM4.25 16.125L9.125 11.25L16.625 18.75H4.25V16.125ZM20.75 18.75H18.7466L15.3716 15.375L17.2466 13.5L20.75 17.0044V18.75ZM14 9.375C14 9.1525 14.066 8.93499 14.1896 8.74998C14.3132 8.56498 14.4889 8.42078 14.6945 8.33564C14.9 8.25049 15.1262 8.22821 15.3445 8.27162C15.5627 8.31502 15.7632 8.42217 15.9205 8.5795C16.0778 8.73684 16.185 8.93729 16.2284 9.15552C16.2718 9.37375 16.2495 9.59995 16.1644 9.80552C16.0792 10.0111 15.935 10.1868 15.75 10.3104C15.565 10.434 15.3475 10.5 15.125 10.5C14.8266 10.5 14.5405 10.3815 14.3295 10.1705C14.1185 9.95952 14 9.67337 14 9.375Z" fill="#888888"/>
                    </svg>}
                </div>
                <div className="relative cursor-pointer">
                    <p className="text-[#0099CE] font-[600] text-[14px] text-center mt-5 lg:mt-0 cursor-pointer">{profileDetails.logo ? 'Update' : 'Upload'} your logo</p>
                    <input onChange={onLogoUpload} accept="image/*" className="absolute top-0 bottom-0 left-0 right-0 w-full h-full opacity-0 cursor-pointer" type="file"/>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <h4 className="mt-10 page-title text-[18px]">Company Details</h4>
                <div className="grid mt-5 gap-y-6">
                    <InputField
                        label='Name of Institution'
                        disabled={true}
                        placeholder=""
                        inputClass="capitalize"
                        value={profileDetails.nameInstitution}
                    />
                    <InputField
                        label='SEC Registration Number'
                        disabled={true}
                        value={profileDetails.secRegistrationNumber}
                    />
                    <TextareaField
                        label='Registered Address'
                        placeholder='Enter full address'
                        error={errors.registeredAddress?.message}
                        onChangeInput={(e) => [setValue('registeredAddress', e.target.value), onRegisteredAddress(e.target.value), setError('registeredAddress', {message: ''})]}
                        value={getValues('registeredAddress')}
                    />
                    <div className="mt-6">
                        <TickerField
                            label="Contact Address is the same as above"
                            onChangeInput={(state) => onSameAddress(state)}
                            checked={sameAddress}
                        />
                    </div>
                    {! sameAddress && <div className=""><TextareaField
                        label='Contact Address'
                        placeholder='Enter full address'
                        error={errors.contactAddress?.message}
                        value={getValues('contactAddress')}
                        onChangeInput={(e) => [setValue('contactAddress', e.target.value), setError('contactAddress', {message: ''})]}
                    /></div>}
                </div>
            </div>
            <div>
                <h4 className="mt-10 page-title text-[18px]">Contact Details of Representatives</h4>
                <p className="text-light-black text-[14px] mt-2">At least 2 contacts required</p>
                {representatives.map((rep, index) => (
                            <div key={index} className="p-4 mt-4 border rounded bg-[#F8F8F8]">
                                <div className="grid gap-4 lg:grid-cols-2">
                                    <div>
                                        <InputField
                                            label="Name"
                                            onChangeInput={(e) => onChangeRepresentativeInput(index, 'name', e)}
                                            value={rep.name}
                                            placeholder="Enter first and last name"
                                            error={errors.contactRepresentatives?.[index]?.name?.message} 
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Position"
                                            onChangeInput={(e) => onChangeRepresentativeInput(index, 'position', e)}
                                            value={rep.position}
                                            placeholder="Enter job title/position"
                                            error={errors.contactRepresentatives?.[index]?.position?.message} 
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Mobile number"
                                            onChangeInput={(e) => onChangeRepresentativeInput(index, 'mobile_number', e)}
                                            type="number"
                                            value={rep.mobile_number}
                                            placeholder="E.g. +2348031231234"
                                            error={errors.contactRepresentatives?.[index]?.mobile_number?.message} 
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Email address"
                                            onChangeInput={(e) => onChangeRepresentativeInput(index, 'email_address', e)}
                                            type="email"
                                            value={rep.email_address}
                                            placeholder="name@company.com"
                                            error={errors.contactRepresentatives?.[index]?.email_address?.message} 
                                        />
                                    </div>
                                    <div className="flex items-center space-x-3">
                                        <TickerField
                                            name="primary_contact"
                                            onChangeInput={() => onPrimaryContact(index)}
                                            label="Set as primary contact"
                                            checked={rep.is_primary}
                                            value={index.toString()}
                                            type="radio"
                                        />
                                        <Tooltip text="The portal account will be created with the email address of the primary contact"/>
                                    </div>
                                </div>
                                <p className="flex justify-end mt-2 font-bold label">
                                    {index > 1 && (
                                        <div onClick={() => removeRepresentative(index)} className="cursor-pointer text-red-800 text-sm font-[400] hover:underline w-fit">
                                           
                                            <span>remove representative</span>
                                        </div>
                                    )}
                                </p>
                            </div>
                        ))}
                    <div className="mt-4">
                        <button type="button" onClick={() => setRepresentatives([...representatives, {} as representativesType])} className="flex items-center space-x-2 w-fit text-[#0099CE]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H12.75V20.25C12.75 20.4489 12.671 20.6397 12.5303 20.7803C12.3897 20.921 12.1989 21 12 21C11.8011 21 11.6103 20.921 11.4697 20.7803C11.329 20.6397 11.25 20.4489 11.25 20.25V12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25H11.25V3.75C11.25 3.55109 11.329 3.36032 11.4697 3.21967C11.6103 3.07902 11.8011 3 12 3C12.1989 3 12.3897 3.07902 12.5303 3.21967C12.671 3.36032 12.75 3.55109 12.75 3.75V11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12Z" fill="#0099CE"/>
                            </svg>
                            <span>Add another contact</span>
                        </button>
                    </div>
            </div>
            <button className="mt-5 pryBtn w-fit">Update Profile</button>
            </form>
        </div>
    );
}

export default UpdateProfile