import NairaSvg from "../../../../components/naira-svg";
import Paystack from '@paystack/inline-js'
import { numberWithCommas } from "../../../../libs/helper-support";

interface PaymentIndividualType{
    name: string,
    amount: string,
    description?: string,
    duration: string,
    isRecurringPayment: boolean,
    isOnboardPayment: boolean,
    uuid: string,
}

interface PaymentType{
    reference: string,
    url: string,
    accessCode: any,
    paymentTypes: PaymentIndividualType[],
    amount:string,
}

interface option{
    option:PaymentType,
}

const popup = new Paystack();


const Payment = ({option}:option) => {
    const onMakePayment = () => {
        //console.log(option);
        //popup.resumeTransaction({accessCode: option.accessCode});
        //popup.;
        //window.open(`${option.url}?rdr=payt`, '_blank');
        window.location.replace(option.url);
    }

    const onIn = () => {
        popup.resumeTransaction(option.accessCode);
    }
    
    return (
        <div>
            <div className="grid gap-4 p-4 bg-white rounded">
            {option.paymentTypes.map((type,i)=>(
                <div key={i} className="flex justify-between">
                <div>
                    <h1>{type.name}</h1>
                    <p className="text-light-black text-[13px]">{type.description ?? ''}</p>
                </div>
                <div>
                    <NairaSvg/> {numberWithCommas(type.amount)}
                </div>
            </div>
            ))}
            <div className="flex justify-between pt-5 border-t">
                <div>
                    <h1>Total</h1>
                </div>
                <div>
                    <NairaSvg/> {numberWithCommas(option.amount)}
                </div>
            </div>
        </div>
        {/* <div className="flex justify-center mt-5">
            <button type="button" onClick={onMakePayment} className="pryBtn w-fit">Pay Now Redirect</button>
        </div> */}
        <div className="flex justify-center mt-5">
            <button type="button" onClick={onMakePayment} className="pryBtn w-fit">Pay Now</button>
        </div>
    </div>
    )
}

export default Payment;