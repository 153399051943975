import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import InputField from "../../../../components/form/input-field";
import ApiRequest from '../../../../libs/ApiCall';
import { EmptyFormInput, SmoothScroll } from '../../../../libs/helper-support';
import { useAuthStore } from '../../../../libs/auth/auth-store';
import { ErrorFlash, SuccessFlash } from '../../../../libs/flash-notification';

interface ChangePasswordType {
    onLoading?: (state:boolean) => void
}   


const ChangePassword = ({onLoading} : ChangePasswordType) => {
    const {handleLogout} = useAuthStore();

    const formSchema = yup.object({
        currentPassword: yup.string().required('Current password is required'),
        password: yup.string().required('New password is required'),
        passwordConfirmation: yup.string().required('New password confirmation is required'),
    });
    
    const  {
      setValue,
      setError,
      formState: {errors},
      handleSubmit,
      getValues,
      reset,
    } = useForm({resolver: yupResolver(formSchema)}); 

    const onSubmit = async (data:any) => {
        onLoading && onLoading(true);
        const request = await ApiRequest({endpoint: '/change-password', method:'POST', payload: data});
        onLoading && onLoading(false);
        reset(EmptyFormInput(formSchema));
        if ( request.status === '200') {
            SuccessFlash("Password changed successfully");
            SuccessFlash("You'll be redirected to login page", 5);
            setTimeout(() => handleLogout(), 3000);
            return;
        }

        ErrorFlash(request.message);

        if (request.status === "payloadValidationError") {
            for (const [field, messages] of Object.entries(request.data)) {
                const input_field : any = field ;
                const error_message : any = messages
                setError(input_field, { type: "manual", message: error_message.join(", ") });
                SmoothScroll();
            }
          }
    }

    return (
        <div className="grid lg:px-10 gap-y-4">
            <h4 className="page-title text-[18px]">Change Password</h4>
            <form onSubmit={handleSubmit(onSubmit)} className="grid mt-5 gap-y-6">
                <InputField
                    label="Current Password"
                    type="password"
                    error={errors.currentPassword?.message}
                    onChangeInput={(e) => [setValue('currentPassword', e.target.value), setError('currentPassword', {message: ''})]}
                    value={getValues('currentPassword')}
                />
                <InputField
                    label="New Password"
                    type="password"
                    error={errors.password?.message}
                    onChangeInput={(e) => [setValue('password', e.target.value), setError('password', {message: ''})]}
                    value={getValues('password')}
                />
                <InputField
                    label="Confirm New Password"
                    type="password"
                    error={errors.passwordConfirmation?.message}
                    onChangeInput={(e) => [setValue('passwordConfirmation', e.target.value), setError('passwordConfirmation', {message: ''})]}
                    value={getValues('passwordConfirmation')}
                />
                <button className="pryBtn w-fit">Change Password</button>
            </form>
        </div>
    );
}

export default ChangePassword