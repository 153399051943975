import { Link, useSearchParams } from "react-router-dom";
import InputField from "../../components/form/input-field";
import GuestLayout from "../../components/layouts/guest";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import ApiRequest from "../../libs/ApiCall";
import { useEffect, useState } from "react";
import { EmptyFormInput } from "../../libs/helper-support";
import NotFound from "../../routes/not-found";
import Logo from "../../components/logo";

const SetPassword = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const token = searchParams.get('token');

    const [pageLoading, setPageLoading] = useState<boolean>(true);

    const [validToken, setValidToken] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    
    const [processState, setProcessState] = useState<string|null>(null);
    
    const [message, setMessage] = useState<string>('');

    const [email, setEmail] = useState<string>('');

    const validateToken = async () => {
        
        const request = await ApiRequest({endpoint: 'auth/verify-forgot-password', method: 'POST', payload: {token}});
        
        setPageLoading(false);
        
        if ( request.status === '200'){
            setEmail(request.data.email);
            setValidToken(true);
            return;
        }
        
        setValidToken(false);
    }

    useEffect(() => {
        validateToken();
    }, []);

    const formSchema = yup.object({
        password: yup.string().required('Password is required'),
        passwordConfirmation: yup.string().required('Confirm new password is required'),
    });

    const  {
        setValue,
        setError,
        formState: {errors},
        handleSubmit,
        getValues,
        reset,
    } = useForm({resolver: yupResolver(formSchema)}); 


    const forgotPassword = async (data:any) => {
        setLoading(true);
        data['email'] = email;
        data['token'] = token;
        const request = await ApiRequest({endpoint: 'auth/reset-password', method: 'POST', payload: data});
        
        setLoading(false);
        
        if ( request.status === '200'){
            reset(EmptyFormInput(formSchema));
            setProcessState('success');
            setMessage('Password reset successfully');
            return;
        }

        setProcessState('error');
        
        setMessage(request.message);

        if (request.status === "payloadValidationError") {
            for (const [field, messages] of Object.entries(request.data)) {
                const input_field : any = field ;
                const error_message : any = messages
                setError(input_field, { type: "manual", message: error_message.join(", ") });
            }
        }
    }
    return (
        <>
            {(! pageLoading && validToken) && (
                <GuestLayout>
                    <form onSubmit={handleSubmit(forgotPassword)} className="mx-auto rounded-[16px] page-outer lg:w-2/5">
                        <div className="flex justify-center mb-5"><Logo/></div>
                        <h1 className="text-center page-title">Create your password</h1>
                        {processState && <div className={`${processState === 'success' ? 'text-green-500' : 'text-red-500'} text-[14px] my-2`}>{message}</div>}
                        {processState === 'success' ? (
                            <Link to={'/login'} className="mt-6 text-[#0099CE] text-[14px] font-[600]">Proceed to Login</Link>
                        )
                        : (
                            <div className="grid mt-6 gap-y-4">
                            <InputField
                                label="Password"
                                type="password"
                                error={errors.password?.message}
                                value={getValues('password')}
                                onChangeInput={(e) => [setValue('password', e.target.value), setError('password', {message: ''})]}
                            />
                            <InputField
                                label="Confirm Password"
                                type="password"
                                error={errors.passwordConfirmation?.message}
                                value={getValues('passwordConfirmation')}
                                onChangeInput={(e) => [setValue('passwordConfirmation', e.target.value), setError('passwordConfirmation', {message: ''})]}
                            />
                            <div className="flex justify-center">
                                <button disabled={loading} className={`${loading && 'opacity-[0.5]'} w-fit pryBtn`}>Create Password</button>
                            </div>
                        </div>
                        )}
                    </form>
                </GuestLayout>
            )}
            {(! pageLoading && ! validToken) && <NotFound/>}
        </>
    );
}

export default SetPassword