import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Login from '../pages/guest/login';
import ForgotPassword from '../pages/guest/forgot-password';
import Register from '../pages/guest/register';
import { AuthToken } from '../libs/auth/auth-store';
import ResetPassword from '../pages/guest/reset-password';
import SetPassword from '../pages/guest/set-password';

type RouteType = {
  path: string;
  element: React.ReactNode;
};

const routes: RouteType[] = [
  { path: '/login', element: <Login /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/register', element: <Register /> },
  { path: '/reset-password', element: <ResetPassword /> },
  { path: '/set-password', element: <SetPassword /> },
];

// Check if authenticated
const middleware = () => {
  const isAuthenticated = Boolean(!!AuthToken());
  return routes.map((route) => ({
    path: route.path,
    element:! isAuthenticated ? route.element : <Navigate to="/" />
  }));
}

const GuestRoutes : RouteObject[] = middleware();

export default GuestRoutes;
