import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Dashboard from '../pages/auth/dashboard';
// import Members from '../pages/auth/member/members';
import Products from '../pages/auth/product/products';
import Transactions from '../pages/auth/transaction/transactions';
import Payment from '../pages/auth/payment';
import Account from '../pages/auth/account/account';
import { AuthToken } from '../libs/auth/auth-store';
// import Users from '../pages/auth/user/users';
// import SingleMember from '../pages/auth/member/single-member';
// import EditMember from '../pages/auth/member/edit-member';

type RouteType = {
  path: string;
  element: React.ReactNode;
};

const routes: RouteType[] = [
  { path: '/', element: <Dashboard /> },
  // { path: '/members', element: <Members/>},
  // { path: '/members/:id', element: <SingleMember/>},
  // { path: '/members/:id/edit', element: <EditMember/>},
  { path: '/products', element: <Products/>},
  { path: '/transactions', element: <Transactions/>},
  { path: '/payment', element: <Payment/>},
  { path: '/account', element: <Account/>},
  // { path: '/users', element: <Users/>},
];

// Check if authenticated
const middleware = () => {
  const isAuthenticated = Boolean(!!AuthToken());
  return routes.map((route) => ({
    path: route.path,
    element: isAuthenticated ? route.element : <Navigate to="/login" />
  }));
}

const AuthRoutes : RouteObject[] = middleware();

export default AuthRoutes;
