import { useRef, useState, useEffect } from "react";

type onChangeInputFunc = (e:string) => void

type OptionType = {
    name: string,
    value: string,
}

type InputType = {
    placeholder?:any,
    label?: string,
    labelClass?: string,
    id?: string,
    value?:string,
    error?:string|null,
    disabled?:boolean,
    onChangeInput?: onChangeInputFunc,
    inputClass?:string,
    options?: OptionType[],
}

const SelectField = ({
    placeholder,
    label,
    labelClass,
    id,
    value,
    error,
    onChangeInput,
    disabled = false,
    inputClass,
    options
} : InputType) => {
    const Id = id ?? label;

    const [showMOptions, setShowMOptions] = useState<boolean>(false);

    const showMOptionsRef = useRef<HTMLLabelElement>(null);

    const getPlaceholder = () => {
        if ( value ){
            const option = options?.find((option) => option.value === value);
            return option?.name ?? '';
        }
        return placeholder ?? `Select ${label}`;
    }

    const onShowOption = (triggerBy = 'default') => {
        triggerBy === 'outsideEvent'  ? setShowMOptions(false) : setShowMOptions(!showMOptions)
    };

    const handleOnClickOutside = (event: MouseEvent) => {
        if (showMOptionsRef.current && ! showMOptionsRef.current.contains(event.target as Node))  onShowOption('outsideEvent');
    }

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('click', handleOnClickOutside);
        
        // Cleanup event listener when the component unmounts
        return () => {
        document.removeEventListener('mousedown', handleOnClickOutside);
        };
      });

      const onSelectOption = (value:string) => {
        onChangeInput && onChangeInput(value);
        setShowMOptions(false);
      }

    return(
        <label onClick={(() => setShowMOptions(!showMOptions))} ref={showMOptionsRef} htmlFor={Id} className="label">
            <span className={`${labelClass}`}>{label}</span>
            <div className={`relative input border-[#E8ECEF] ${inputClass} border ${error && 'border-red-500'} ${disabled && 'text-light-black'} text-black bg-white justify-between`}>
                <span>{getPlaceholder()}</span>
                <span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3538 6.35354L8.35378 11.3535C8.30735 11.4 8.2522 11.4369 8.1915 11.4621C8.13081 11.4872 8.06574 11.5002 8.00003 11.5002C7.93433 11.5002 7.86926 11.4872 7.80856 11.4621C7.74786 11.4369 7.69272 11.4 7.64628 11.3535L2.64628 6.35354C2.55246 6.25972 2.49976 6.13247 2.49976 5.99979C2.49976 5.86711 2.55246 5.73986 2.64628 5.64604C2.7401 5.55222 2.86735 5.49951 3.00003 5.49951C3.13272 5.49951 3.25996 5.55222 3.35378 5.64604L8.00003 10.2929L12.6463 5.64604C12.6927 5.59958 12.7479 5.56273 12.8086 5.53759C12.8693 5.51245 12.9343 5.49951 13 5.49951C13.0657 5.49951 13.1308 5.51245 13.1915 5.53759C13.2522 5.56273 13.3073 5.59958 13.3538 5.64604C13.4002 5.69249 13.4371 5.74764 13.4622 5.80834C13.4874 5.86904 13.5003 5.93409 13.5003 5.99979C13.5003 6.06549 13.4874 6.13054 13.4622 6.19124C13.4371 6.25193 13.4002 6.30708 13.3538 6.35354Z" fill="#888888"/>
                </svg>
                </span>
                {showMOptions && (
                    <div className="absolute left-0 right-0 w-full bg-white h-[10em] overflow-y-auto z-10 rounded shadow-md border p-2 top-0">
                    <ul className="grid gap-y-2">
                        <li onClick={() => onSelectOption('')} className="p-1 cursor-pointer hover:bg-gray-100">{placeholder ?? `Select ${label}`}</li>
                        {options?.map((option) => <li key={option.name} onClick={() => onSelectOption(option.value)} className="p-1 cursor-pointer hover:bg-gray-100">{option.name}</li>)}
                    </ul>
                </div>
                )}
            </div>
            {error && <p className="my-1 text-[13px] text-red-500 capitalize label font-[400]">{error}</p>}
        </label>
    );
}

export default SelectField;