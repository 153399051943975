import React, { useEffect, useState } from 'react';
import SideSlidingModal from '../../../components/modal/wrapper/side-sliding-modal';
import InputField from '../../../components/form/input-field';
import SelectField from '../../../components/form/select-field';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import ApiRequest from '../../../libs/ApiCall';
import { CurrencyTypes, EmptyFormInput, RegulatorTypes, SecurityTypes, SmoothScroll, TransactionRolesTypes, TransactionTypes } from "../../../libs/helper-support";
import { ErrorFlash, SuccessFlash } from '../../../libs/flash-notification';
import DatePicker from '../../../components/form/date-picker';
import DatePickerField from '../../../components/form/date-picker';

type AddButtonType = {
    buttonDesign: JSX.Element,
    onSuccess?: (data:any) => void,
    onLoading?: (state:boolean) => void
}
const AddTransaction = ({buttonDesign, onSuccess, onLoading} : AddButtonType) => {
 
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onsetForceModalClose = (state:any) => {setModalOpen(state)}

  type OptionType = {
    value: string,
    name: string
  }

  const transactionTypeOptions : OptionType[] = TransactionTypes;

  const securityOptions : OptionType[] = SecurityTypes;

  const regulatorOptions : OptionType[] = RegulatorTypes;

  const currencyOptions : OptionType [] = CurrencyTypes

  const roleOptions : OptionType[] = TransactionRolesTypes; 

  const formSchema = yup.object({
    issuer: yup.string().required(),
    type: yup.string().required('Transaction type is required'),
    security: yup.string(),
    regulatorQuasi: yup.string(),
    series: yup.string(),
    transactionSize: yup.string().required('Transaction size is required'),
    transactionSizeCurrency: yup.string().required('This field is required'),
    completionIssueDate: yup.string(),
    role: yup.string(),
});

useEffect(() => {
  setValue('transactionSizeCurrency', 'NGN')
})

const  {
  setValue,
  setError,
  formState: {errors},
  handleSubmit,
  getValues,
  reset,
} = useForm({resolver: yupResolver(formSchema)}); 

const onSubmit = async (data:any) => {
  onLoading && onLoading(true);
  const request = await ApiRequest({endpoint: '/transaction/add-new', method:'POST', payload: data});
  onLoading && onLoading(false);
  if ( request.status === '200') {
    onSuccess && onSuccess(request.data);
    setModalOpen(false);
    reset(EmptyFormInput(formSchema));
    SuccessFlash("Transaction added successfully");
    return;
  }

  ErrorFlash(request.message);

  if (request.status === "payloadValidationError") {
    for (const [field, messages] of Object.entries(request.data)) {
        const input_field : any = field ;
        const error_message : any = messages
        setError(input_field, { type: "manual", message: error_message.join(", ") });
        SmoothScroll();
    }
  }
}

    return (
        <SideSlidingModal buttonTrigger={buttonDesign} forceModalClose={modalOpen} onSetForceModalClose={onsetForceModalClose}>
          <div>
          <h2 className="page-title text-[20px]">Add New Transaction</h2>
          <p className="mt-4 text-light-black text-[14px] font-[450]">Enter relevant details and ignore fields that do not apply</p>
            <form onSubmit={handleSubmit(onSubmit)} className='grid mt-10 gap-y-5'>
              <div>
                <InputField
                  placeholder='Enter company name'
                  label='Client/Issuer'
                  error={errors.issuer?.message}
                  onChangeInput={(e) => [setValue('issuer', e.target.value), setError('issuer', {message: ''})]}
                  value={getValues('issuer')}
                />
              </div>
              <div>
                <SelectField
                  label='Transaction Type'
                  placeholder='Select'
                  options={transactionTypeOptions}
                  error={errors.type?.message}
                  onChangeInput={(e) => [setValue('type', e), setError('type', {message: ''})]}
                  value={getValues('type')}
                />
              </div>
              <div>
                <SelectField
                  label='Security'
                  placeholder='Select'
                  options={securityOptions}
                  error={errors.security?.message}
                  onChangeInput={(e) => [setValue('security', e), setError('security', {message: ''})]}
                  value={getValues('security')}
                />
              </div>
              <div>
                <SelectField
                  label='Regulator/Quasi Regulator'
                  placeholder='Select'
                  options={regulatorOptions}
                  error={errors.regulatorQuasi?.message}
                  onChangeInput={(e) => [setValue('regulatorQuasi', e), setError('regulatorQuasi', {message: ''})]}
                  value={getValues('regulatorQuasi')}
                />
              </div>
              <div>
                <InputField
                  placeholder='Enter series'
                  label='Series (if applicable)'
                  error={errors.series?.message}
                  onChangeInput={(e) => [setValue('series', e.target.value), setError('series', {message: ''})]}
                  value={getValues('series')}
                />
              </div>
              <div className='flex w-full space-x-3'>
                  <div className='w-4/5'>
                    <InputField
                      placeholder='Enter amount'
                      type='number'
                      label='Transaction Size'
                  error={errors.transactionSize?.message}
                  onChangeInput={(e) => [setValue('transactionSize', e.target.value), setError('transactionSize', {message: ''})]}
                  value={getValues('transactionSize')}
                    />
                  </div>
                  <div className='w-1/5'>
                    <SelectField
                      placeholder='Select'
                      label='currency'
                      labelClass="opacity-0"
                      options={currencyOptions}
                      error={errors.transactionSizeCurrency?.message}
                      onChangeInput={(e) => [setValue('transactionSizeCurrency', e), setError('transactionSizeCurrency', {message: ''})]}
                      value={getValues('transactionSizeCurrency') ?? 'NGN'}
                    />
                  </div>
              </div>
              <div>
                <DatePickerField
                  label='Transaction Completion Date/Issue Date'
                  placeholder='Choose date'
                 // type='date'
                  error={errors.completionIssueDate?.message}
                  onChangeInput={(e) => [setValue('completionIssueDate', e), setError('completionIssueDate', {message: ''})]}
                  value={getValues('completionIssueDate')}
                />
              </div>
              <div>
                <SelectField
                  label='Role'
                  placeholder='Select'
                  options={roleOptions}
                  error={errors.role?.message}
                  onChangeInput={(e) => [setValue('role', e), setError('role', {message: ''})]}
                  value={getValues('role')}
                />
              </div>
            <div className='mt-8'>
                <button className='pryBtn w-fit'>Create Transaction</button>
            </div>
            </form>
          </div>
        </SideSlidingModal>
    )
}

export default AddTransaction