import { Link } from "react-router-dom";
import InputField from "../../components/form/input-field";
import GuestLayout from "../../components/layouts/guest";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from "react";
import ApiRequest from "../../libs/ApiCall";
import { SmoothScroll } from "../../libs/helper-support";
import { useAuthStore } from "../../libs/auth/auth-store";
import Logo from "../../components/logo";
const Login = () => {

    const {handleLogin} = useAuthStore();
    
    const [loading, setLoading] = useState<boolean>(false);
    const Login = async (data:any) => {
        setLoading(true);
        const request = await ApiRequest({endpoint: 'auth/login', method: 'POST', payload: data});
        setLoading(false);

        if ( request.status === '200') {
            handleLogin(request.data);
            return;
        }

        if (request.status === "payloadValidationError") {
            for (const [field, messages] of Object.entries(request.data)) {
                const input_field : any = field ;
                const error_message : any = messages
                setError(input_field, { type: "manual", message: error_message.join(", ") });
                SmoothScroll();
            }
        }

        if (request.status === "300") {
            setError('email', {type: "manual", message: request.message});
            setValue('password', '');
        }
    }

    const formSchema = yup.object({
        email: yup.string().email().required(),
        password: yup.string().required()
    });

    const  {
        setValue,
        setError,
        formState: {errors},
        handleSubmit,
        getValues
    } = useForm({resolver: yupResolver(formSchema)}); 

    return (
        <GuestLayout>
            <div className="mx-auto rounded-[16px] page-outer lg:w-2/5">
                <div className="flex justify-center mb-5"><Logo/></div>
                <h1 className="text-center page-title">Login</h1>
                <p className="text-light-black text-[14px] mt-8">Sign in with your registered email address and password</p>
                <form onSubmit={handleSubmit(Login)} className="grid mt-6 gap-y-4">
                    <InputField
                        label="Email address"
                        placeholder="name@company.com"
                        type="email"
                        error={errors.email?.message}
                        onChangeInput={(e) => [setValue('email', e.target.value), setError('email', {message: ''})]}
                        value={getValues('email')}
                    />
                    <InputField
                        label="Password"
                        type="password"
                        error={errors.password?.message}
                        onChangeInput={(e) => [setValue('password', e.target.value), setError('password', {message: ''})]}
                        value={getValues('password')}
                    />
                    <Link to={'/forgot-password'} className="text-[#0099CE] text-[14px] font-[600]">Reset Password</Link>
                    <div className="flex justify-center">
                        <button disabled={loading} className={`w-fit pryBtn ${loading && 'opacity-[0.5]'}`}>Sign In</button>
                    </div>
                </form>
            </div>
        </GuestLayout>
    );
}

export default Login