import { Link } from "react-router-dom";


const RegSuccess = () => {
    return (
        <div>
            <div className="flex flex-col items-center p-4 bg-white rounded ">
                <div className="flex flex-col items-center py-10 space-y-6 text-center lg:w-3/4">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M27.1344 15.3656C27.2506 15.4817 27.3428 15.6196 27.4057 15.7713C27.4686 15.9231 27.501 16.0857 27.501 16.25C27.501 16.4143 27.4686 16.5769 27.4057 16.7287C27.3428 16.8804 27.2506 17.0183 27.1344 17.1344L18.3844 25.8844C18.2683 26.0006 18.1304 26.0928 17.9787 26.1557C17.8269 26.2186 17.6643 26.251 17.5 26.251C17.3357 26.251 17.1731 26.2186 17.0213 26.1557C16.8696 26.0928 16.7317 26.0006 16.6156 25.8844L12.8656 22.1344C12.6311 21.8998 12.4993 21.5817 12.4993 21.25C12.4993 20.9183 12.6311 20.6002 12.8656 20.3656C13.1002 20.1311 13.4183 19.9993 13.75 19.9993C14.0817 19.9993 14.3998 20.1311 14.6344 20.3656L17.5 23.2328L25.3656 15.3656C25.4817 15.2494 25.6196 15.1572 25.7713 15.0943C25.9231 15.0314 26.0857 14.999 26.25 14.999C26.4143 14.999 26.5769 15.0314 26.7287 15.0943C26.8804 15.1572 27.0183 15.2494 27.1344 15.3656ZM36.25 20C36.25 23.2139 35.297 26.3557 33.5114 29.028C31.7258 31.7003 29.1879 33.7831 26.2186 35.013C23.2493 36.243 19.982 36.5648 16.8298 35.9378C13.6776 35.3107 10.7821 33.7631 8.50952 31.4905C6.23692 29.2179 4.68926 26.3224 4.06225 23.1702C3.43524 20.018 3.75704 16.7507 4.98696 13.7814C6.21689 10.8121 8.29969 8.27419 10.972 6.48862C13.6443 4.70305 16.7861 3.75 20 3.75C24.3084 3.75455 28.439 5.46806 31.4855 8.51454C34.5319 11.561 36.2455 15.6916 36.25 20ZM33.75 20C33.75 17.2805 32.9436 14.6221 31.4327 12.3609C29.9218 10.0997 27.7744 8.33736 25.2619 7.29666C22.7494 6.25595 19.9848 5.98366 17.3175 6.5142C14.6503 7.04475 12.2003 8.35431 10.2773 10.2773C8.35432 12.2003 7.04476 14.6503 6.51421 17.3175C5.98366 19.9847 6.25596 22.7494 7.29666 25.2619C8.33737 27.7744 10.0997 29.9218 12.3609 31.4327C14.6221 32.9436 17.2805 33.75 20 33.75C23.6455 33.7459 27.1404 32.2959 29.7182 29.7182C32.2959 27.1404 33.7459 23.6455 33.75 20Z" fill="#1D0A5F"/>
                    </svg>
                    <h1 className="text-black font-[700] font-[merriweather] text-[18px]">We have received your application</h1>
                    <p className="text-[#888888] font-[400] text-[16px]">We will send your membership information via email upon approval within 72 hours.</p>
                </div>
            </div>
            <Link to="/" className="flex items-center justify-center mt-5 space-x-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.0001 12C21.0001 12.1989 20.9211 12.3897 20.7804 12.5303C20.6398 12.671 20.449 12.75 20.2501 12.75H5.56041L11.0307 18.2194C11.1004 18.289 11.1557 18.3718 11.1934 18.4628C11.2311 18.5539 11.2505 18.6514 11.2505 18.75C11.2505 18.8485 11.2311 18.9461 11.1934 19.0372C11.1557 19.1282 11.1004 19.2109 11.0307 19.2806C10.961 19.3503 10.8783 19.4056 10.7873 19.4433C10.6962 19.481 10.5986 19.5004 10.5001 19.5004C10.4016 19.5004 10.304 19.481 10.2129 19.4433C10.1219 19.4056 10.0392 19.3503 9.96948 19.2806L3.21948 12.5306C3.14974 12.461 3.09443 12.3782 3.05668 12.2872C3.01894 12.1961 2.99951 12.0986 2.99951 12C2.99951 11.9014 3.01894 11.8038 3.05668 11.7128C3.09443 11.6217 3.14974 11.539 3.21948 11.4694L9.96948 4.71936C10.1102 4.57863 10.3011 4.49957 10.5001 4.49957C10.6991 4.49957 10.89 4.57863 11.0307 4.71936C11.1715 4.8601 11.2505 5.05097 11.2505 5.24999C11.2505 5.44901 11.1715 5.63988 11.0307 5.78061L5.56041 11.25H20.2501C20.449 11.25 20.6398 11.329 20.7804 11.4697C20.9211 11.6103 21.0001 11.8011 21.0001 12Z" fill="#0099CE"/>
                </svg>
                <span className="text-primary font-[600] text-[14px]">Back to AIHN Website</span>
            </Link>
        </div>
    );
}

export default RegSuccess;