import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

interface LayoutProps {
    children: ReactNode;
  }

const GuestLayout : React.FC <LayoutProps>= ({children}) => {
    
    const currentUrl = useLocation().pathname;

 type PageContent ={       
        text: string;
        link: string;
        linkText: string;
    }

    const getPageContent =  () : PageContent  =>  {
   
        if (currentUrl === '/register') {
            return {
                text: 'Already a Member?',
                link: '/login',
                linkText: 'Log In'
            }
        }  
        
        return {
            text: 'Not yet a Member?',
            link: '/register',
            linkText: 'Apply to Join'
        };
    }

    return (
        <div className="max-w-screen-xl p-10 mx-auto">
            <div className="flex justify-between">
                <Link to={'/'} className="flex items-center space-x-2 text-[14px]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 12.0001C21 12.199 20.921 12.3897 20.7803 12.5304C20.6397 12.671 20.4489 12.7501 20.25 12.7501H5.56032L11.0306 18.2194C11.1003 18.2891 11.1556 18.3718 11.1933 18.4629C11.231 18.5539 11.2504 18.6515 11.2504 18.7501C11.2504 18.8486 11.231 18.9462 11.1933 19.0372C11.1556 19.1283 11.1003 19.211 11.0306 19.2807C10.9609 19.3504 10.8782 19.4056 10.7872 19.4433C10.6961 19.4811 10.5985 19.5005 10.5 19.5005C10.4015 19.5005 10.3039 19.4811 10.2128 19.4433C10.1218 19.4056 10.0391 19.3504 9.96938 19.2807L3.21938 12.5307C3.14965 12.461 3.09433 12.3783 3.05658 12.2873C3.01884 12.1962 2.99941 12.0986 2.99941 12.0001C2.99941 11.9015 3.01884 11.8039 3.05658 11.7128C3.09433 11.6218 3.14965 11.5391 3.21938 11.4694L9.96938 4.71943C10.1101 4.5787 10.301 4.49963 10.5 4.49963C10.699 4.49963 10.8899 4.5787 11.0306 4.71943C11.1714 4.86016 11.2504 5.05103 11.2504 5.25005C11.2504 5.44907 11.1714 5.63995 11.0306 5.78068L5.56032 11.2501H20.25C20.4489 11.2501 20.6397 11.3291 20.7803 11.4697C20.921 11.6104 21 11.8011 21 12.0001Z" fill="#414141"/>
                    </svg>
                    <span>Back to website</span>
                </Link>
                <p className="text-[14px]">
                    {getPageContent().text} <Link className="text-[#0099CE] font-[600]" to={getPageContent().link}>{getPageContent().linkText}</Link>
                </p>
            </div>
            <div className="mt-10">
                {children}
            </div>
        </div>
    );
}

export default GuestLayout