import { useState } from "react";
import AuthLayout from "../../../components/layouts/auth";
import UpdateProfile from "./partials/update-profile";
import ChangePassword from "./partials/change-password";

const Account = () => {

    const [display, setDisplay] = useState<string>('update-profile');

    const [pageLoader, setPageLoader] = useState<boolean>(false);

    return (
        <AuthLayout pageLoader={pageLoader}>
            <div className="page-outer">
                <h1 className="page-title">Account</h1>
                <div className="mt-10 lg:space-x-5 lg:flex">
                    <div className="bg-[#F8F8F8] py-5 flex flex-col text-[14px] font-[600] lg:w-1/5 space-y-5 h-fit">
                        <button onClick={() => setDisplay('update-profile')} type="button" className={`${display === 'update-profile' && 'text-secondary bg-white'} p-3 ml-3 text-left h-fit`}>Update Details</button>
                        <button onClick={() => setDisplay('change-password')} type="button" className={`${display === 'change-password' && 'text-secondary bg-white'} p-3 ml-3 text-left h-fit`}>Change Password</button>
                    </div>
                    <div className="mt-5 lg:w-2/3 lg:mt-0">
                        {display === 'update-profile' && <UpdateProfile onLoading={(state) => setPageLoader(state)}/>}
                        {display === 'change-password' && <ChangePassword onLoading={(state) => setPageLoader(state)}/>}
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
}

export default Account